import { AspectRatio, Button, Card, CardFooter, CardHeader, Heading } from "@chakra-ui/react";
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { AttributesWrapper, IdWrapper } from "../../cms/types/core";
import { FeaturedExperienceTypeAttributes } from "../../cms/types/home";
import { getImageUrl } from "../../cms/functions/images";

type Props = Readonly<IdWrapper & AttributesWrapper<FeaturedExperienceTypeAttributes>>;

export default function PopularExperienceCard(props: Props) {
    const navigate = useNavigate();

    return (
        <Card
            maxWidth={{base: "xs", md: "sm"}}
            as={ReactRouterLink}
            to={`/experiences?experienceTypeId=${props.id}`}>
            <CardHeader>
                <Heading size='md'>{props.attributes.Name}</Heading>
            </CardHeader>
            <AspectRatio 
                role="img"
                aria-label={props.attributes.image.data.attributes.alternativeText}
                ratio={1} 
                backgroundImage={getImageUrl(props.attributes.image)}
                backgroundSize="cover"
                >
                    <></>
                </AspectRatio>
            <CardFooter>
                <Button onClick={() => navigate(`/experiences?experienceTypeId=${props.id}`)}>Find out more</Button>
            </CardFooter>
        </Card>
    );
}