import { Route, Routes } from "react-router-dom";
import AboutUs from "../../pages/AboutUs";
import ContactUs from "../../pages/ContactUs";
import Experiences from "../../pages/Expereinces";
import PartnerWithUs from "../../pages/PartnerWithUs";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import useLayoutQuery from "../../cms/queries/layoutQuery";
import Home from "../../pages/Home";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

function Pages() {
    const layoutQuery = useLayoutQuery();

    if (!layoutQuery.data) {
        return (<></>);
    }

    return (
        <QueryParamProvider adapter={ReactRouter6Adapter}>
                <Routes>
                    <Route path="/" element={<Home layoutData={layoutQuery.data} />} />
                    <Route path="/experiences" element={<Experiences layoutData={layoutQuery.data} />} />
                    <Route path="/aboutus" element={<AboutUs layoutData={layoutQuery.data} />} />
                    <Route path="/contactus" element={<ContactUs layoutData={layoutQuery.data} />} />
                    <Route path="/partnerwithus" element={<PartnerWithUs layoutData={layoutQuery.data} />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy layoutData={layoutQuery.data} />} />
                </Routes>
        </QueryParamProvider>
    );
}

export default Pages;