import { Grid, Heading, VStack } from "@chakra-ui/react";
import PopularExperienceCard from "./PopularExperienceCard";
import objectHash from "object-hash";
import { AttributesWrapper, IdWrapper } from "../../cms/types/core";
import { FeaturedExperienceTypeAttributes } from "../../cms/types/home";

type Props = Readonly<{
    title: string,
    experiences: (IdWrapper & AttributesWrapper<FeaturedExperienceTypeAttributes>)[]
}>;

export default function PopularExperiences(props: Props) {
    return (
        <VStack {...props} maxWidth={"100vw"} minHeight={{base: "100vh", lg: "50vh"}}>
            <Heading  textAlign={"center"} size={"lg"} color="gray.800" padding={5}>{props.title}</Heading>
                <Grid
                        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
                        gap={5}
                        padding={10}
                        paddingTop={0}
                        width="full"
                        >
                    {props.experiences.map((x, i) => (
                        <PopularExperienceCard key={objectHash({x, i})} {...x}/>
                    ))}
                </Grid>    
        </VStack>
    );
}