import { gql, useQuery } from "@apollo/client";
import { AttributesWrapper, DataWrapper } from "../types/core";
import { ContactAttributes } from "../types/contact";

const query = gql`
  query {
    contactUs {
      data {
        attributes {
          text
        }
      }
    }
  }
`;

type ContactResponse = {
  contactUs: DataWrapper<AttributesWrapper<ContactAttributes>>
};

function useContactQuery() {
    return useQuery<ContactResponse>(query);
}
  
export default useContactQuery;