import { gql, useQuery } from "@apollo/client";
import { AttributesWrapper, DataWrapper } from "../types/core";
import { PartnerWithUsAttributes } from "../types/partnerWithUs";

const query = gql`
  query {
    partnerWithUs {
      data {
        attributes {
          text
        }
      }
    }
  }
`;

type PartnerWithUsResponse = {
  partnerWithUs: DataWrapper<AttributesWrapper<PartnerWithUsAttributes>>
};

function usePartnerWithUsQuery() {
    return useQuery<PartnerWithUsResponse>(query);
}
  
export default usePartnerWithUsQuery;