import { AttributesWrapper, DataWrapper, ImageAttributes } from "../types/core";

export function getImageUrl(image: DataWrapper<AttributesWrapper<ImageAttributes>>) {
    const formats = image.data.attributes.formats;

    const formatKeys = Object.keys(formats);

    const format = formats[formatKeys[0]];

    return format.url;
}