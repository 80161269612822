import { VStack } from '@chakra-ui/react'
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import { SiteLayoutProps } from './types/SiteLayoutProps';
import usePrivacyPolicyQuery from '../cms/queries/privacyPolicyQuery';
import Markdown from 'react-markdown';

export default function PrivacyPolicy(props: Readonly<SiteLayoutProps>) {
    const privacyPolicyQuery = usePrivacyPolicyQuery();

    return (<>
        <VStack maxWidth={"100vw"} minHeight={"100vh"}>
            <Header header={props.layoutData.header.data.attributes} allNavigations={props.layoutData.navigations} />  
            <VStack width={"full"} padding={10} spacing={5} alignItems={"start"}>
                {privacyPolicyQuery.data && <Markdown>{privacyPolicyQuery.data.privacyPolicy.data.attributes.text}</Markdown>}
            </VStack>
        </VStack>
        <Footer {...props.layoutData.footer.data.attributes} />      
    </>);
}