import { gql, useQuery } from "@apollo/client";
import { AttributesWrapper, DataWrapper } from "../types/core";
import { HomeAttributes } from "../types/home";

const query = gql`
  query {
    home {
      data {
        attributes {
          background_image {
            data {
              attributes {
                alternativeText
                formats
              }
            }
          }
          intro_title
          intro_button {
            text
            navigation {
              data {
                attributes {
                  path
                }
              }
            }
          }
          featured_experiences_title
          featured_experience_types(pagination: { limit: 3 }) {
            data {
              id,
              attributes {
                Name
                image {
                  data {
                    attributes {
                      alternativeText
                      formats
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type HomeResponse = {
  home: DataWrapper<AttributesWrapper<HomeAttributes>>
};

function useHomeQuery() {
  return useQuery<HomeResponse>(query);
}

export default useHomeQuery;
