import { FormControl, FormLabel, Select, Grid, Button, useBreakpointValue, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { CityAttributes, CountyAttributes, ExperienceTypeAttributes } from "../../cms/types/experienceFilters";
import { IdWrapper, AttributesWrapper } from "../../cms/types/core";

type Props = {
    experienceTypes: (IdWrapper & AttributesWrapper<ExperienceTypeAttributes>)[],
    cities: (IdWrapper & AttributesWrapper<CityAttributes>)[],
    counties: (IdWrapper & AttributesWrapper<CountyAttributes>)[]
    experienceTypeId: number | undefined,
    setExperienceTypeId: (experienceTypeId: number | undefined) => void,
    countyId: number | undefined,
    setCountyId: (countyId: number | undefined) => void,
    cityId: number | undefined,
    setCityId: (cityId: number | undefined) => void,
}

export default function ExperienceFilters(props: Readonly<Props>) {
    const [toggleFilters, setToggleFilters] = useState<boolean>(false);
    const [showClearFilters, setShowClearFilters] = useState<boolean>(false);

    useEffect(() => {
        if (props.experienceTypeId === undefined && 
            props.countyId === undefined && 
            props.cityId === undefined) {
            setShowClearFilters(false);
        } else {
            setShowClearFilters(true);
        }
    }, [props.experienceTypeId, props.countyId, props.cityId]);

    const showFilters = useBreakpointValue({
        base: toggleFilters,
        md: true
    });

    const handleExperienceTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedExperienceTypeId = parseInt(e.target.value);
               
        if (!Number.isNaN(selectedExperienceTypeId)) {
            props.setExperienceTypeId(selectedExperienceTypeId);
        } else {
            props.setExperienceTypeId(undefined);
        }
    };

    const handleCountyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCountyId = parseInt(e.target.value);
        
        if (!Number.isNaN(selectedCountyId)) {
            props.setCountyId(selectedCountyId);
        } else {
            props.setCountyId(undefined);
        }
    };

    const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCityId = parseInt(e.target.value);
        
        if (!Number.isNaN(selectedCityId)) {
            props.setCityId(selectedCityId);
        } else {
            props.setCityId(undefined);
        }
    };

    const clearFilters = () => {
        props.setExperienceTypeId(undefined);
        props.setCountyId(undefined);
        props.setCityId(undefined);
    };

    return (<>
            <HStack alignSelf={"start"}
                marginLeft={10}
                marginTop={5}
                hideFrom={"md"}
                spacing={5}>
                    <Button 
                        variant={"outline"}
                        rightIcon={toggleFilters ? <ExpandLess /> : <ExpandMore />}
                        onClick={() => setToggleFilters(!toggleFilters)}
                    >
                        {toggleFilters ? "Hide filters" : "Show filters"}
                    </Button>
                    <Button 
                        variant={"link"}
                        onClick={() => clearFilters()}
                        hidden={!showClearFilters}
                        >   
                        Clear Filters
                    </Button>
            </HStack>
            <Grid
                templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)", lg: "repeat(4, 1fr)" }}
                gap={10}
                padding={10}
                width="full"
                hidden={!showFilters}
                >
                <FormControl>
                    <FormLabel>Type</FormLabel>
                    <Select 
                        placeholder='Select type' 
                        value={props.experienceTypeId || ''}
                        onChange={handleExperienceTypeChange}>
                        {props.experienceTypes.map(et => 
                            <option 
                                value={et.id}
                                key={`et-${et.attributes.Name}`}>
                                    {et.attributes.Name}
                            </option>)}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>County</FormLabel>
                    <Select 
                        placeholder='Select county' 
                        value={props.countyId || ''}
                        onChange={handleCountyChange}>
                        {props.counties.map(c => 
                            <option 
                                value={c.id}
                                key={`c-${c.attributes.Name}`}>
                                    {c.attributes.Name}
                            </option>)}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Location</FormLabel>
                    <Select 
                        placeholder='Select location' 
                        value={props.cityId || ''}
                        onChange={handleLocationChange}>
                        {props.cities.map(l => 
                            <option 
                                value={l.id}
                                key={`l-${l.attributes.Name}`}>
                                    {l.attributes.Name}
                            </option>)}
                    </Select>
                </FormControl>
                <FormControl hideBelow={"lg"}>
                    <Button 
                        variant={"link"}
                        onClick={() => clearFilters()}
                        hidden={!showClearFilters}
                        >   
                        Clear Filters
                    </Button>
                </FormControl>
            </Grid>
    </>);
}