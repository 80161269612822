import { ChakraProvider } from '@chakra-ui/react'
import CmsProvider from './components/cms/CmsProvider';
import Pages from './components/layout/Pages';

function App() {
  return (
    <CmsProvider>
      <ChakraProvider>
        <Pages />
      </ChakraProvider>
    </CmsProvider>
  );
}

export default App;
