import { VStack } from '@chakra-ui/react'
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import { SiteLayoutProps } from './types/SiteLayoutProps';
import useAboutQuery from '../cms/queries/aboutQuery';
import Markdown from 'react-markdown';

export default function AboutUs(props: Readonly<SiteLayoutProps>) {
    const aboutQuery = useAboutQuery();

    return (<>
        <VStack maxWidth={"100vw"} minHeight={"100vh"}>
            <Header header={props.layoutData.header.data.attributes} allNavigations={props.layoutData.navigations} />  
            <VStack width={"full"} padding={10} spacing={5} alignItems={"start"}>
                {aboutQuery.data && <Markdown>{aboutQuery.data.aboutUs.data.attributes.text}</Markdown>}
            </VStack>
        </VStack>
        <Footer {...props.layoutData.footer.data.attributes} />      
    </>);
}