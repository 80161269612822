import { gql, useQuery } from "@apollo/client";
import { AttributesWrapper, DataWrapper, IdWrapper } from "../types/core";
import { CityAttributes, CountyAttributes, ExperienceTypeAttributes } from "../types/experienceFilters";

const query = gql`
  query {
    experienceTypes(pagination: { limit: -1 }, sort: "Name:asc") {
      data {
        id
        attributes {
          Name
        }
      }
    }
    cities(pagination: { limit: -1 }, sort: "Name:asc") {
      data {
        id
        attributes {
          Name
          county {
            data {
              id
            }
          }
        }
      }
    }
    counties(pagination: { limit: -1 }, sort: "Name:asc") {
      data {
        id
        attributes {
          Name
        }
      }
    }
  }
`;

type ExperienceFiltersResponse = {
    experienceTypes: DataWrapper<(IdWrapper & AttributesWrapper<ExperienceTypeAttributes>)[]>,
    cities: DataWrapper<(IdWrapper & AttributesWrapper<CityAttributes>)[]>,
    counties: DataWrapper<(IdWrapper & AttributesWrapper<CountyAttributes>)[]>
};

function useExperienceFiltersQuery() {
    return useQuery<ExperienceFiltersResponse>(query);
}
  
export default useExperienceFiltersQuery;