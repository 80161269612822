import { getImageUrl } from "../../cms/functions/images";
import { IdWrapper, AttributesWrapper } from "../../cms/types/core";
import { CityAttributes, CountyAttributes } from "../../cms/types/experienceFilters";
import { ExperienceAttributes } from "../../cms/types/experiences";
import { Card, CardHeader, Heading, AspectRatio, CardBody, CardFooter, Button, Text } from "@chakra-ui/react";

type Props = {
    experience: ExperienceAttributes,
    cities: (IdWrapper & AttributesWrapper<CityAttributes>)[],
    counties: (IdWrapper & AttributesWrapper<CountyAttributes>)[]
}

export default function ExperienceCard(props: Readonly<Props>) {

    function cityName()
    {
        return props.cities.find(x => x.id === props.experience.city.data.id)?.attributes.Name;
    }

    function countyName()
    {
        const countyId = props.cities.find(x => x.id === props.experience.city.data.id)?.attributes.county.data.id;

        return props.counties.find(x => x.id === countyId)?.attributes.Name;
    }

    return (
        <Card
            overflow="hidden" 
            >
                <CardHeader>
                    <Heading size="md">{props.experience.Name}</Heading>
                </CardHeader>
                <AspectRatio 
                    role="img"
                    aria-label={props.experience.image.data.attributes.alternativeText}
                    ratio={1} 
                    backgroundImage={getImageUrl(props.experience.image)}
                    backgroundSize="cover">
                        <></>
                </AspectRatio>
                <CardBody>
                    <Text size="sm">{cityName()}, {countyName()}</Text>
                    <Text size="sm">£{props.experience.Price}</Text>
                </CardBody>
                <CardFooter>
                    <Button onClick={() => window.open(props.experience.link, '_blank')}>Find out more</Button>
                </CardFooter>
        </Card>
    );

}