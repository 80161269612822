import { Box, Flex, HStack, Heading, IconButton, Link, Menu, MenuButton, MenuItem, MenuList, Text, Image } from '@chakra-ui/react'
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom'
import { HeaderAttributes, NavigationsAttributes } from '../../cms/types/layout';
import objectHash from 'object-hash';
import { getImageUrl } from '../../cms/functions/images';
import { Helmet, HelmetProvider } from 'react-helmet-async';

type Props = {
    header: HeaderAttributes,
    allNavigations: NavigationsAttributes
}

export default function Header(props: Readonly<Props>) {
    const location = useLocation();

    const currentNavigation = props.allNavigations.data.find(x => 
        x.attributes.path.toLowerCase() === location.pathname.toLowerCase());

    const browserTitle = `Train Experiences${currentNavigation ? ` - ${currentNavigation.attributes.name}` : ""}`;

    return (
        <HelmetProvider> 
            <Helmet>
                <title>{browserTitle}</title>
                <meta property="og:title" content={browserTitle} />
            </Helmet>
            <Flex width={"100%"} direction={"row"} justifyContent={"space-between"} paddingBottom={2} background={"rgba(255, 255, 255, 0.3)"}>
                <HStack marginTop={3} marginLeft={5} fontWeight={"semibold"}  divider={<Text marginLeft={2} marginRight={2}>|</Text>}>
                    <Link fontSize={"xx-large"} as={ReactRouterLink} to={"/"}><Image src={getImageUrl(props.header.logo)} alt={props.header.logo.data.attributes.alternativeText} maxHeight={"16"} /></Link>
                </HStack>
                <HStack marginTop={3} marginRight={5} fontWeight={"semibold"}  divider={<Text marginLeft={2} marginRight={2}>|</Text>} hideBelow={"md"} aria-label='Menu'>
                    {props.header.navigations.data.map(x => 
                        <Link 
                            key={objectHash(x)} 
                            as={ReactRouterLink} 
                            to={x.attributes.path}>
                                {x.attributes.name}
                        </Link>)}
                </HStack>
                <Box hideFrom={"md"}  marginTop={5} marginRight={5}>
                    <Menu>
                        {({ isOpen }) => (<>
                            <MenuButton
                                as={IconButton}
                                aria-label='Menu'
                                icon={isOpen ? <MenuOpenIcon /> : <MenuIcon />}
                                variant='outline'
                            />
                            <MenuList>
                                {props.header.navigations.data.map(x => 
                                    <MenuItem 
                                        key={objectHash(x)}
                                        as={ReactRouterLink} 
                                        to={x.attributes.path} 
                                        _focus={{background:"none"}}>
                                        {x.attributes.name}
                                    </MenuItem>)}
                            </MenuList>
                        </>)}
                    </Menu>
                </Box>
            </Flex>
            {currentNavigation && <HStack width={"full"} padding={10} paddingBottom={0}>
                <Heading size={"2xl"} >{currentNavigation.attributes.name}</Heading>
            </HStack>}
        </HelmetProvider>
    );
}