import { VStack, Grid, Text } from "@chakra-ui/react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import objectHash from "object-hash";
import ExperienceCard from "../components/experiences/ExperienceCard";
import ExperienceFilters from "../components/experiences/ExperienceFilters";
import { SiteLayoutProps } from "./types/SiteLayoutProps";
import useExperiencesQuery from "../cms/queries/experiencesQuery";
import useExperienceFiltersQuery from "../cms/queries/experienceFiltersQuery";
import { NumberParam, useQueryParam } from "use-query-params";
import PaginationControl from "../components/pagintation/PaginationControl";

export default function Experiences(props: Readonly<SiteLayoutProps>) {
    const [experienceTypeId, setExperienceTypeId] = useQueryParam('experienceTypeId', NumberParam);
    const [cityId, setCityId] = useQueryParam('cityId', NumberParam);
    const [countyId, setCountyId] = useQueryParam('countyId', NumberParam);
    const [page, setPage] = useQueryParam('page', NumberParam);

    const experienceFiltersQuery = useExperienceFiltersQuery();

    const experiencesQuery = useExperiencesQuery({
        experienceTypeId: experienceTypeId || undefined,
        cityId: cityId || undefined,
        countyId: countyId || undefined,
        page: page || undefined
    });

    return (
        <>
            <VStack maxWidth={"100vw"} minHeight={"100vh"}>
                <Header header={props.layoutData.header.data.attributes} allNavigations={props.layoutData.navigations} />
                {experienceFiltersQuery.data && <ExperienceFilters 
                    experienceTypes={experienceFiltersQuery.data.experienceTypes.data}
                    counties={experienceFiltersQuery.data.counties.data}
                    cities={experienceFiltersQuery.data.cities.data}
                    experienceTypeId={experienceTypeId || undefined}
                    setExperienceTypeId={setExperienceTypeId}
                    countyId={countyId || undefined}
                    setCountyId={setCountyId}
                    cityId={cityId || undefined}
                    setCityId={setCityId} />}
                <Grid
                    templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)", lg: "repeat(4, 1fr)" }}
                    gap={10}
                    padding={10}
                    width="full"
                    >
                    {experiencesQuery.data && experiencesQuery.data.experiences.data.map((x, i) => {
                        if (!experienceFiltersQuery.data) {
                            return null;
                        }

                        return (
                            <ExperienceCard 
                                key={objectHash({x, i})} 
                                experience={x.attributes}
                                cities={experienceFiltersQuery.data.cities.data}
                                counties={experienceFiltersQuery.data.counties.data} />
                        );
                    })}
                </Grid>
                {experiencesQuery.data && experiencesQuery.data.experiences.meta.pagination.pageCount > 1 && <PaginationControl 
                    {...experiencesQuery.data.experiences.meta.pagination} 
                    setPage={setPage} />}
                {experiencesQuery.data && experiencesQuery.data.experiences.data.length === 0 && <Text>No experiences to display.</Text>}
            </VStack>
            <Footer {...props.layoutData.footer.data.attributes} />
        </>
    )
}