import { gql, useQuery } from "@apollo/client";
import { AttributesWrapper, DataWrapper } from "../types/core";
import { PrivacyPolicyAttributes } from "../types/privacyPolicy";

const query = gql`
  query {
    privacyPolicy {
      data {
        attributes {
          text
        }
      }
    }
  }
`;

type PrivacyPolicyResponse = {
  privacyPolicy: DataWrapper<AttributesWrapper<PrivacyPolicyAttributes>>
};

function usePrivacyPolicyQuery() {
    return useQuery<PrivacyPolicyResponse>(query);
}
  
export default usePrivacyPolicyQuery;