import { gql, useQuery } from "@apollo/client";
import { AttributesWrapper, DataWrapper } from "../types/core";
import { AboutAttributes } from "../types/about";

const query = gql`
  query {
    aboutUs {
      data {
        attributes {
          text
        }
      }
    }
  }
`;

type AboutResponse = {
    aboutUs: DataWrapper<AttributesWrapper<AboutAttributes>>
};

function useAboutQuery() {
    return useQuery<AboutResponse>(query);
}
  
export default useAboutQuery;