import { gql, useQuery } from "@apollo/client";
import { AttributesWrapper, DataWrapper, NavigationAttributes } from "../types/core";
import { FooterAttributes, HeaderAttributes } from "../types/layout";

const query = gql`
  query {
    header {
      data {
        attributes {
          logo {
            data {
              attributes {
                formats
                alternativeText
              }
            }
          }
          navigations(pagination: { limit: -1 }) {
            data {
              attributes {
                name
                path
              }
            }
          }
        }
      }
    }
    footer {
      data {
        attributes {
          navigations(pagination: { limit: -1 }) {
            data {
              attributes {
                name
                path
              }
            }
          }
        }
      }
    },
    navigations(pagination: { limit: -1 }) {
      data {
        attributes {
          name
          path
        }
      }
    }
  }
`;

export type LayoutResponse = {
    header: DataWrapper<AttributesWrapper<HeaderAttributes>>,
    footer: DataWrapper<AttributesWrapper<FooterAttributes>>,
    navigations: DataWrapper<AttributesWrapper<NavigationAttributes>[]>
};

function useLayoutQuery() {
    return useQuery<LayoutResponse>(query);
}
  
export default useLayoutQuery;