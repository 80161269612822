import { gql, useQuery } from "@apollo/client";
import { ExperienceAttributes } from "../types/experiences";
import { DataWrapper, AttributesWrapper, Meta } from "../types/core";

const query = gql`
  query experiences($experienceTypeId: ID, $cityId: ID, $countyId: ID, $page: Int) {
    experiences(
      filters: {
        experience_type: { id: { eq: $experienceTypeId } }
        city: { id: { eq: $cityId }, county: { id: { eq: $countyId } } }
      },
      pagination: {
        page: $page
        pageSize: 25
      },
      sort: "id:desc"
    ) {
      data {
        attributes {
          Name
          city {
            data {
              id
            }
          }
          Price
          link
          image {
            data {
              attributes {
                formats
                alternativeText
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;

type ExperiencesResponse = {
  experiences: DataWrapper<AttributesWrapper<ExperienceAttributes>[]> & Meta
};

export type ExperiencesVariables = {
  countyId: number | undefined,
  cityId: number | undefined,
  experienceTypeId: number | undefined,
  page: number | undefined
}

function useExperiencesQuery(variables: ExperiencesVariables) {
  return useQuery<ExperiencesResponse, ExperiencesVariables>(query, {
    variables,
    fetchPolicy: "network-only"
  });
}

export default useExperiencesQuery;