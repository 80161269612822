import { Button, Center, HStack, Heading, Image, Spacer, VStack } from '@chakra-ui/react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { useNavigate  } from "react-router-dom";
import PopularExperiences from '../components/experiences/PopularExperiences'
import useHomeQuery from '../cms/queries/homeQuery';
import { SiteLayoutProps } from './types/SiteLayoutProps';
import { getImageUrl } from '../cms/functions/images';

export default function Home(props: Readonly<SiteLayoutProps>) {
    const navigate = useNavigate();

    const homeData = useHomeQuery();

    return (
        <>
            {homeData.data && 
                <Image 
                src={getImageUrl(homeData.data.home.data.attributes.background_image)} 
                alt={homeData.data.home.data.attributes.background_image.data.attributes.alternativeText} 
                width={"100vw"} 
                height={{base: "100vh", lg: "50vh"}} 
                position={"absolute"} 
                top={"0"} 
                zIndex={-1} 
                opacity={0.5} 
                objectFit={"cover"} />}
            <VStack 
                maxWidth={"100vw"} 
                minHeight={{base: "100vh", lg: "50vh"}}>
                <Header header={props.layoutData.header.data.attributes} allNavigations={props.layoutData.navigations} />
                <Spacer />
                <Center 
                    padding={5} 
                    textAlign={"center"}>
                    <Heading 
                        size={"2xl"} 
                        color="gray.800">
                            {homeData.data?.home.data.attributes.intro_title}
                    </Heading>
                </Center>
                <Spacer />
                <HStack>
                    {homeData.data &&
                        <Button 
                            colorScheme="teal" 
                            paddingX={10} 
                            variant='solid' 
                            size="lg" 
                            border={"1px"} 
                            borderColor={"gray.800"} 
                            onClick={() => 
                                homeData.data && 
                                navigate(homeData.data.home.data.attributes.intro_button.navigation.data.attributes.path)}>
                                {homeData.data.home.data.attributes.intro_button.text}
                        </Button>}
                </HStack>
                <Spacer  />
            </VStack>
            {homeData.data && 
                <PopularExperiences 
                    title={homeData.data.home.data.attributes.featured_experiences_title}
                    experiences={homeData.data.home.data.attributes.featured_experience_types.data} />}
            <Footer {...props.layoutData.footer.data.attributes} />
        </>
    )
}