import { VStack } from '@chakra-ui/react'
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import { SiteLayoutProps } from './types/SiteLayoutProps';
import useContactQuery from '../cms/queries/contactQuery';
import Markdown from 'react-markdown';

export default function ContactUs(props: Readonly<SiteLayoutProps>) {
    const contactQuery = useContactQuery();

    return (<>
        <VStack maxWidth={"100vw"} minHeight={"100vh"}>
            <Header header={props.layoutData.header.data.attributes} allNavigations={props.layoutData.navigations} />  

            <VStack width={"full"} padding={10} spacing={5} alignItems={"start"}>
                {contactQuery.data && <Markdown>{contactQuery.data.contactUs.data.attributes.text}</Markdown>}
            </VStack>
        </VStack>
        <Footer {...props.layoutData.footer.data.attributes} />      
    </>);
}