import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { ReactElement } from "react";

const apolloClient = new ApolloClient({
    uri: process.env.REACT_APP_CMS_GQL_ENDPOINT!,
    cache: new InMemoryCache(),
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CMS_GQL_TOKEN!}`
    }
});

function CmsProvider(props: Readonly<{ children: ReactElement }>) {
    return (
        <ApolloProvider client={apolloClient}>
            {props.children}
        </ApolloProvider>
    );
}

export default CmsProvider;