import { Button, FormControl, HStack, Spacer, FormLabel, Select, Flex } from "@chakra-ui/react";

type Props = {
    total: number,
    page: number,
    pageSize: number,
    pageCount: number,
    setPage: (page: number) => void
};

function PaginationControl(props: Props) {
    function setPreviousPage() {
        if (props.page > 1) {
            props.setPage(props.page - 1);
        }
    }

    function setNextPage() {
        if (props.page < props.pageCount) {
            props.setPage(props.page + 1);
        }
    }

    const handlePageSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const page = parseInt(e.target.value);
        
        if (!Number.isNaN(page) && page >= 1 && page <= props.total) {
            props.setPage(page);
        }
    };

    return (
        <Flex justify="center" align="center" mt={4}>
            <Button
                size="sm"
                variant="outline"
                onClick={setPreviousPage}
                disabled={props.page <= 1}
                mr={2}
            >
                Previous
            </Button>
            
            <FormControl w="auto">
                <FormLabel srOnly>Page</FormLabel>
                <Select 
                    size="sm"
                    value={props.page}
                    onChange={handlePageSelect}
                >
                    {Array.from({ length: props.pageCount }, (_, index) => (
                        <option key={index} value={index + 1}>
                            {index + 1}
                        </option>
                    ))}
                </Select>
            </FormControl>
            
            <Button
                size="sm"
                variant="outline"
                onClick={setNextPage}
                disabled={props.page >= props.pageCount}
                ml={2}
            >
                Next
            </Button>
        </Flex>
    );
}

export default PaginationControl;